import ApiRequest    from '../../api/request.js';
import FormLoader    from '../Form/FormLoader';
import FormValidated from '../Form/FormValidated';
import { ApiManager } from '../../managers';
import {AuthHeader} from '../authHeader';
import React 		 		from 'react';
import { Link, Redirect }  from 'react-router-dom';
import StyledComponent from 'styled-components';
import Qs 	 from 'query-string';


/**
	Handles displaying a page model and user interactions
  State:
  @param 	{bool}		isLoading		If loading is occuring on the page or not
  */
export default class PageController extends React.Component
{
	_css = null;
	_isMounted = false;
	_form = null;

	// MARK: - Constructor
	constructor(props)
	{
		super(props);

		// Setup state
		const state =
		{
			isLoading: false
		};

		// Setup components
		for(let componentItr = 0; componentItr < props.pageComponents.length; componentItr++)
		{
			// Clear state on form fields
			if(props.pageComponents[componentItr].type === 'form')
			{
				const formComponent = props.pageComponents[componentItr].form;
				for(let i = 0; i < formComponent.length; i++)
				{
					if(formComponent[i].type !== 'button' &&
							formComponent[i].type !== 'space' &&
								formComponent[i].type !== 'link')
					{
						state[formComponent[i].id] = '';
					}
				}
				break;
			}
		}

		this.state = state;

		// Style component
		this._css = this.styleComponent(props.siteManager);

		// References
		this._form = React.createRef();
	}

	componentDidMount()
	{
		this._isMounted = true;
	}
	componentWillUnmount()
	{
		this._isMounted = false;
	}

	// MARK: - Form related
	formOnSubmit = async (evt, buttonData) =>
	{
		try
		{
			evt.preventDefault();

			// Validations
			if(this.state.isLoading)
			{
				return false;
			}

			// Init
			this.setState({ isLoading: true });

			// Find form component
			let formComponent = null;
			for(let i = 0; i < this.props.pageComponents.length; i++)
			{
				if(this.props.pageComponents[i].type === 'form')
				{
					formComponent = this.props.pageComponents[i];
					break;
				}
			}

			// Get params
			let params = {};
			if(formComponent !== null)
			{
				for(let i = 0; i < formComponent.form.length; i++)
				{
					if(formComponent.form[i].type !== 'button' &&
						formComponent.form[i].type !== 'space' &&
						formComponent.form[i].type !== 'link' &&
						formComponent.form[i].type !== '_form_footer_')
					{
						// Backend will validate _confirm_ fields match
						//if(formComponent.form[i].id.indexOf('_confirm_') === -1)
						//{
							params[formComponent.form[i].id] = this._form.current.getValueFor(formComponent.form[i].id);
						//}
					}
				}

				// Get hidden data to put into params
				if(formComponent.hiddenData && formComponent.hiddenData.length > 0)
				{
					let querystring = Qs.parse(this.props.location.search);
					for(let i = 0; i < formComponent.hiddenData.length; i++)
					{
						// Read query string
						if(formComponent.hiddenData[i].data === '_qs_')
						{
							params[formComponent.hiddenData[i].id] = querystring[formComponent.hiddenData[i].dataField];
						}
						// Static data
						else
						{
							params[formComponent.hiddenData[i].id] = formComponent.hiddenData[i].data;
						}
					}
				}
			}

      let response = await ApiRequest.sendRequest("post", params, buttonData.route);
			// Success
			if(response.data.error === null)
		  {
				// Handle success actions
				for(let i = 0; i < buttonData.successActions.length; i++)
				{
					switch(buttonData.successActions[i].action)
					{
						case 'alert':
							this.props.showAlert(	true,
																		buttonData.successActions[i].title,
																		response.data[buttonData.successActions[i].messageDataField],
																		buttonData.successActions[i].variant,
																		buttonData.successActions[i].dismissText);
							break;
						case 'setCookie':
							this.props.cookies.set(	buttonData.successActions[i].cookieField,
																			response.data[buttonData.successActions[i].dataField],
																			{ path: '/' });
						  if(buttonData.successActions[i].cookieField === 'token')
							{
								// If changing API token we need to update API manager
								console.log("PageController.reinitializing ApiManager");
								ApiManager.Init(this.props.cookies.get('token'));
							}
							break;
						case 'setState':
							this.props.updateMasterState({ [buttonData.successActions[i].id]: buttonData.successActions[i].dataField === '' ? buttonData.successActions[i].data : response.data[buttonData.successActions[i].dataField] });
							break;
						default:
							break;
					}
				}

				this.setState({ isLoading: false });
			}
			// Failed
	   	else
			{
				this.setState({ isLoading: false });
		   	this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
		 	}
	  }
	  catch(err)
	  {
			console.log(err.stack);
	    this.setState({ isLoading: false });
	  	this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
    }
	}

	resetComponents = () =>
	{
		console.log('PageController.resetComponents()');
		const state = {};
		// Setup components
		for(let componentItr = 0; componentItr < this.props.pageComponents.length; componentItr++)
		{
			// Clear state on form fields
			if(this.props.pageComponents[componentItr].type === 'form')
			{
				const formComponent = this.props.pageComponents[componentItr].form;
				for(let i = 0; i < formComponent.length; i++)
				{
					if(formComponent[i].type !== 'button' &&
							formComponent[i].type !== 'space' &&
								formComponent[i].type !== 'link')
					{
						state[formComponent[i].id] = '';
					}
				}
				break;
			}
		}
		this._form.current.updateAllFormInputValues();
		this.setState(state);
	}


	// MARK: - Render
	shouldComponentUpdate(nextProps, nextState)
  {
		// If page switched we need to
		if(nextProps.path !== this.props.path)
		{
			this.props.reloadPageData();
		}

		// If style manager provided now, update css and page
    if(nextProps.siteManager !== null && this.props.siteManager === null)
    {
      this._css = this.styleComponent(nextProps.siteManager);
			return true;
    }
    // Only render if button status changed
    return (this.state.isLoading !== nextState.isLoading ||
						this.props.pageComponents !== nextProps.pageComponents);
  }

	componentDidUpdate()
	{
		if(this.props.cookies.get('user'))
		{
			this.props.updateMasterState({ 'path': '/home' });
			console.log('Redirecting to /home');
			this.props.history.push('/home');
		}
	}

	render()
	{
		console.log('PageController.render()');

		// Setup onclick handlers
		// Find form component
		let formComponent = null;
		for(let i = 0; i < this.props.pageComponents.length; i++)
		{
			if(this.props.pageComponents[i].type === 'form')
			{
				formComponent = this.props.pageComponents[i];
				break;
			}
		}
		if(formComponent !== null)
		{
			for(let i = 0; i < formComponent.form.length; i++)
			{
				// Hijack onclick handler for buttons
				if(formComponent.form[i].type === 'button')
				{
					formComponent.form[i].onClick = this.formOnSubmit;
				}
				// Reset components when changing page
				else if(formComponent.form[i].type === 'link')
				{
					formComponent.form[i].onClick = this.resetComponents;
				}
			}
		}

		if(this.props.pageComponents.form)
		{
			console.log(this.props.pageComponents.form);
		}

		return (
		<this._css>
			{this.props.pageComponents.map( (component, i) =>
			{
				//console.log(component);
				switch(component.type)
				{
					case 'authHeader':
						return (
							<AuthHeader
								imageSrc={component.imageSrc}
								header={component.header}
								detail={component.detail}
								key={`component-${i}-auth-header`}
							/>
						);
					case 'form':
						return (
						<div key={`component-${i}-row`}>
							<FormLoader
								isLoading={this.state.isLoading}
								key={`component-${i}-loader`}
							/>
							<FormValidated
								ref={this._form}
								formInputs={component ? component.form : []}
								formOnChange={(change) => { this.setState({[change.id]: change.value}) }}
								showErrorList={false}
								validateOnInit={false}
								columns={-1}
								theme='main'
								siteManager={this.props.siteManager}
								dynamicInputs={true}
								key={`component-${i}-form`}
							/>
						</div>
						);
					default: return (<div key={`component-${i}-div`}></div>);
				}
			})}
		</this._css>
		);
	}


	/**
		Creates a styled components from the components/fields
		as well as setups up the form from the page data
		@param	{SiteManager}	siteManager	Singleton reference to site manager
		@returns	{Tuple.<StyledComponent, [FormInputs]>}	A styled component and form input array
	*/
	styleComponent = (siteManager) =>
  {
    /*var linkText = '#007BF9';
    var linkTextSelected = '#FFFFFF';
    var bgColor = '#FFF';

    if(siteManager !== null)
    {
      linkText = siteManager.getColorFor('Side Navigation', 'Link Text');
      linkTextSelected = siteManager.getColorFor('Side Navigation', 'Link Text (Selected)');
      bgColor = siteManager.getColorFor('Side Navigation', 'Background');
    }*/

    return StyledComponent.div`
			margin-top: 100px;
			margin-bottom: 100px;
    `;
  }
}
