export default
{
  friends:
  {
    title: 'Friends',
    titleEmpty: 'You have not added anyone yet'
  },
  emails:
  {
    title: 'Email Manager',
    preview: 'Preview',
    infoMessage: 'You have access to these database records: Member and Event\n\
Surround them with {{ }} in order for the code to be able to parse it properly and do the replacement.\n\
Use dot notation to access a property in the record\n\
For example to access the member\'s name you would type {{Member.name}}\n\
The event name would be {{Event.name}}\n\
If you wanted the subject to be "Hi John Doe!" you would put a Subject of: Hi {{Member.name}}!\n\
\n\
Make sure to press the save button on the bottom right to save your changes when you are done.\n\
You will be able to preview how the email will look below.\n\
Once you are done make sure to associate this template with an event.',
    infoMessageRight: 'Available Member properties: \n\
    - name\n\
    - photo\n\
    - raised\n\
    - memberID\n\
    - goal\n\
    - email\n\
    - fundraisingPageID\n\
    - street\n\
    - city\n\
    - state\n\
    - zipcode\n\
    \n\
    Available Event properties: \n\
    - campaignID\n\
    - name',
    infoTitle: 'How to use'
  },
  register:
  {
    popupDialog:
    {
      okayBtn: 'Okay!'
    },
    statementOfFaith:
    {
      button: 'Statement of Faith',
      title: 'Statement of Faith',
      body: 'We believe the Bible is inspired and empowered by God, infallible and authoritative.\n\
      We believe in one eternal God who exists as three separate persons: the Father, Son, and Holy Spirit.\n\
      \n\
      We believe Jesus Christ is the Son of God, born of a virgin, lived a sinless life as He walked among men, demonstrated the authority and power of God in works and speech, died on the cross, rose from the dead on the third day, and is now seated at the right hand of God having accomplished all that is necessary for man\'s salvation.\n\
      \n\
      We believe it is essential for man to repent of sin and by faith receive the finished work of Christ by believing in his heart and confessing Him as Lord with his mouth and resulting in regeneration by the Holy Spirit.\n\
      \n\
      We believe the Holy Spirit is continuing the work He started at Pentecost empowering believers to live a godly life and continue in all the works of Jesus.\n\
      \n\
      We believe all born-again believers have been commissionedto share the complete Gospel to all the world.\n\
      \n\
      We believe in water baptism, in the Baptism in the Holy Spirit as distinct from the New Birth, in speaking with tongues as the Spirit of God gives utterance (Acts 2:4), in the gifts of the Spirit, and the evidence of the fruit of the Spirit.We believe that all of these are available to believers.\n\
      \n\
      We believe in divine healing, through faith in the Name of Jesus Christ, and that healing is included in the Redemption.\n\
      \n\
      We believe in the Christian\'s hope-the soon-coming, personal return of the Lord Jesus Christ.'
    }
  },
  reports:
  {
    title: 'Report'
  },
  messages:
  {
    autocomplete:
    {
      placeholder: 'Enter username',
      send: 'Start conversation'
    },
    conversations:
    {
      title: 'Conversations',
      titleEmpty: 'No conversations have been started yet'
    },
    header:
    {
      description: 'Start A Conversation',
      title: 'Messages'
    }
  },
  posts:
  {
    header:
    {
      title: 'Status Updates',
      description: 'Reaching the lost and teaching the found.'
    },
    create:
    {
      title: 'Update Status',
      text: 'Post New Update',
      files: 'Add Photos/Videos',
      cancel: 'Cancel Update',
      submit: 'Post This Update',
      fileLabel: 'Choose file(s)',
    },
    post:
    {
      actions:
      {
        like: 'Like this post',
        unlike: 'You like this',
        comment: 'Comment on post',
        comments: 'Comments'
      },
      comments:
      {
        sendButton: 'Comment',
        sendPlaceholder: 'Send a comment...'
      },
      comment:
      {
        save: 'Save'
      }
    }
  },
  settings:
  {
    title: 'Settings'
  }
};
