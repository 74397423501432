import React from 'react';
import TextField from '@material-ui/core/TextField';

/**
    @param  filters   table headers that should be treated as filterable columns
    @param  onChange  function that takes in the filter id and the event for the text on change
*/
function EnhancedTableFilter({ filters, onChange, disabled, filterValues })
{
  /**
    https://material-ui.com/components/text-fields/#textfield
      standard, filled, outlined
  */
  const variant = "outlined";
  const filterStyle =
  {
    paddingLeft: '25px',
    padding: '12px 25px',
    width: `${100 / filters.length}%`
  };

  return (
    <div className="table-filter">
      {filters.length > 0 &&
        filters.map((filter, index) => (
          <div style={filterStyle} key={`filter-column-${index}`}>
            <TextField
              disabled={disabled}
              variant={variant}
              label={filter.label}
              onChange={(evt) => onChange(filter.id, evt)}
              value={filterValues ? (filterValues.get(filter.id) === undefined ? '' : filterValues.get(filter.id))
                                  : ''}
            />
          </div>
        ))}
      </div>
  );
}



export default EnhancedTableFilter;
