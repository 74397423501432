import ApiRequest    from '../../api/request.js';
import FormLoader    from '../Form/FormLoader';
import {schemaFieldToFormInput} from '../Form/utility';
import Table    from '../Table/Table';
import React 		 from 'react';


/**
  State:
    @param 	{bool} 		a		Definition

  Props:
    @param  {String}  model   Name of model we are in context of
*/
export default class DataManager extends React.Component
{
	_isMounted = false;
  _refreshData = false;
	_table = null;

	// MARK: - Constructor
	constructor(props)
	{
		super(props);
		this.state =
		{
			isLoading: false,
			tableData: null,

      modelDoc: null,

			updateFormInputs: [],
      createFormInputs: [],
		}

		this._table = React.createRef();
	}

	componentDidMount()
	{

    if(!this._isMounted)
    {
	     this._isMounted = true;
	     this.loadData();
    }
	}

  /**
  	Load model
	*/
	loadData = async () =>
	{
		this.setState({ isLoading: true });
		var params =
    {
      model: this.props.model,
      params:
      {

      }
    };

    if(this.props.model === 'configuration')
    {
      params.params.display = true;
    }

		try
		{
			// Get data
      // TODO: Setup to use ApiManager instead
			var response = await ApiRequest.sendRequest("post", params, "data/query", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
        return;
			}

      var updateFormInputs = [];
      var createFormInputs = [];
      var formInput = null;
			for(var i = 0; i < response.data.model.schemaFields.length; i++)
			{
				// @todo need to change order to store member info inside document so don't need to populate 8000+ member documents
				if((['donation', 'order'].includes(this.props.model) && response.data.model.schemaFields[i].reference !== 'classymember') || !['donation', 'order'].includes(this.props.model)) {
					formInput = await schemaFieldToFormInput(response.data.model.schemaFields[i], this.props.cookies.get('token'));
					if(formInput !== null)
					{
						if(response.data.model.schemaFields[i].managedUpdateForm)
						{
							// TODO: Added this to fix a bug that came up last minute
							// may not be the best fix
							const updateInput = {...formInput};
							updateInput.managedUpdateForm = true;
							updateFormInputs.push(updateInput);
						}
						createFormInputs.push(formInput);
					}
				}
			}

			this.setState({
				isLoading: false,
				tableData: response.data.results,
        modelDoc: response.data.model,
				updateFormInputs: [...updateFormInputs],
        createFormInputs: [...createFormInputs],
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}


  /**
    Determine if column should be shown in attachable drop down
    @param  {String}  name  The column name to check
    @param  {String}  fieldNameInPtr  The name of the field in attachable record that has will object ID in it. Want to filter that out
    @returns  {Bool}  if it is valid or not
  */
  isValidAttachableColumnName = (name, fieldNameInPtr) =>
  {
    // TODO: Make backend remove these from the object and format date
    const notAttachable = ['_id', 'createdOn', 'updatedOn', 'isDeleted', 'createdBy', '__v', fieldNameInPtr];
    return (notAttachable.indexOf(name) === -1);
  }


	/**
		This will check to see if there are any other model documents that we can attach this to
    @param  {Mongo.Document}  model   Model
    @returns {Array.<FormInput>} An array of drop down form inputs representing all valid records we can attach the newly created record to
	*/
	findAttachable = async (model) =>
	{
		// Find any schemaFields with type = "reference" and reference = model.name and isArray = true
		// Query models to see who schemafield belongs to
		// Query model on that schemaField[name] = model.name
		// Query model documents and display in drop down to choose to attach to
		const params = { model: this.props.model};
		try
		{
			// Get data
			const response = await ApiRequest.sendRequest("post", params, "data/find-attachable", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
        return;
			}

			const formInputs = [];

			// Iterate attachable models
			const keys = Object.keys(response.data.results);
			for(let i = 0; i < keys.length; i++)
			{
        const fieldNameInPtr = keys[i].substr(keys[i].indexOf('_') + 1, keys[i].length);

				// Build option drop down list of records from these models we can attach to
				const options = response.data.results[keys[i]].map( (record) =>
	  		{
					const recordProps = Object.keys(record);
					let textToDisplay = "";
					for(let j = 0; j < recordProps.length; j++)
					{
						// Don't show object Id
						if(this.isValidAttachableColumnName(recordProps[j], fieldNameInPtr))
						{
							textToDisplay = textToDisplay.concat(record[recordProps[j]]);
							if(j != recordProps.length -1 && this.isValidAttachableColumnName(recordProps[i+1], fieldNameInPtr))
							{
								textToDisplay = textToDisplay.concat(':');
							}
						}
					}

	  			return {text: textToDisplay, value: record._id};
	  		});

				const formInput =
				{
					label: 'Attach to ' + keys[i].substr(0, keys[i].indexOf('_')) + ' record using field \"' + fieldNameInPtr + '\"',
					tooltip: 'Leave this blank to ignore it.\nThe selected record in the drop down has a reference field which is an array of type ' + model.name + '.\nThe record you are creating now will be added to the selected records reference array field.',
					id: '_attach_' + keys[i],
					element: 'select',
					type: 'select',
					required: '',
					value: '',
					options: options
				};
				formInputs.push(formInput);
			}

			return formInputs;
		}
		catch(err)
		{
			console.log(err);
			return null;
		}
	}

	tableDidStartLoading = (action) =>
  {
		switch(action)
		{
			case 'update':
				break;
			case 'delete':
				break;
			default: break;
		}
    this.setState({ isLoading: true });
  }

	/**
			@param 	{JSON}		action 		action.type = 'delete, update' and action.data = newly returned data
			@param 	{String}	message 	Message returned from API
			@param 	{String}	error 		Error message or null if not contained

			action:
			{
				type: 'delete',
				data: {record}
			}

	*/
	tableDidFinishLoading = (action, message, error) =>
	{
    console.log(error);
		try
		{
			if(error === null || error === "" || error === undefined)
			{
				let modifiedData = this._table.current.handleAction(action, this.state.tableData);
				this.setState({ isLoading: false, tableData: modifiedData });
				this.props.showAlert(true, 'Success', message, 'success');
			}
			// Failed
			else
			{
				console.log(error);
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', error, 'danger');
			}
		}
		catch(err)
		{
			console.log(err + "\nStack:\n" + err.stack);
		}
	}


	// MARK: - Render
  componentDidUpdate()
  {
    if(this._refreshData)
    {
      this._refreshData = false;

      this.loadData();
    }
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    // Signal in componentdidUpdate that we need to do more work
    if(this.props.model !== nextProps.model)
    {
      this._refreshData = true;

      // Clear table selection since data will be altered
      if(this.table && this.table.current)
      {
        this._table.current.clearAll();
      }
    }

    return (this.props.model !== nextProps.model ||
              this.props.siteManager !== nextProps.siteManager ||
                this.state.isLoading !== nextState.isLoading ||
                  this.state.updateFormInputs !== nextState.updateFormInputs ||
                    this.state.createFormInputs !== nextState.createFormInputs);
  }

	render()
	{
  	return (
		<>
			<FormLoader isLoading={this.state.isLoading}/>
      {this.state.modelDoc &&
  			<Table
  				ref={this._table}
  				data={this.state.tableData}
  				headers={this.state.modelDoc.tableProperties.headers}
  				model={this.state.modelDoc.name}

  				selectAllEnabled={true}
  				multiSelectEnabled={true}

  				defaultSort={this.state.modelDoc.tableProperties.defaultSort}
  				sortEnabled={true}

  				tableDidStartLoading={this.tableDidStartLoading}
  				tableDidFinishLoading={(action, message, error) => this.tableDidFinishLoading(action, message, error)}

  				title={this.state.modelDoc.name + ' records'}

  				isDeleteAvailable={true}

  				isUpdateAvailable={true}
  				updateFormInputs={this.state.updateFormInputs}

  				isCreateAvailable={this.state.modelDoc.name !== 'order'}
  				createFormInputs={this.state.createFormInputs}

          isCsvAvailable={true}

  				cookies={this.props.cookies}
  				siteManager={this.props.siteManager}

					showAlert={this.props.showAlert}
  			/>}
		</>
		);
	}
}
