import React from 'react';
import StyledComponent from 'styled-components';

const Footer = (props) =>
{
  var bgColor = '#222';
  var textColor = '#FFF';
  let fixed = false;
  if(props.siteManager !== null && props.siteManager !== undefined)
  {
    const footerComponent = props.siteManager.getComponent('Footer');
    bgColor = footerComponent.fields.get('Background').color;
    textColor = footerComponent.fields.get('Text').color;
    fixed = footerComponent.properties.fixed;
  }

  const _css = StyledComponent.div`
    .footer
    {
      background-color: ${bgColor};
      color: ${textColor} !important;
      position: ${(fixed ? 'fixed' : 'relative')};
      bottom: 0;
      width: 100%;
      height: 60px;
    }
  `;

  return (
    <_css>
      <footer className='footer mt-auto py-3'>
        <div className='container'>{props.copyrightText}</div>
      </footer>
    </_css>
  );
}
export default Footer;
