import ApiRequest  from '../api/request.js';

export default class User
{
  /**
    Interact with social routes
    @note Parameters come in an object named params
    @param {JSON}  params.id  ID of the document being liked
    @param {String}  params.model is the model type being liked
    @param  {String}  params.action   Is the action to take (like|report)
    @param  {String}  apiToken  Token so we can talk to backend
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Interact(params, apiToken)
  {
    try
    {
      const socialParams =
      {
        entityType: params.model,
        id: params.id
      };
      let response = await ApiRequest.sendRequest("post", socialParams, "user/" + params.action, apiToken);
      //console.log(response.data);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }
}
