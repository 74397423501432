import ApiRequest  from '../api/request.js';


export default class Email
{
  /**
    Interact with email routes
    @note Parameters come in an object named params and may contain more than what is just labeled here
    look at actual method called for action to determine actual parameters
    @param {String}  params.model is the model type being interacted
    @param  {String}  params.action   Is the action to take (create|delete|update)
    @param  {String}  apiToken  Token so we can talk to backend
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Interact(params, apiToken)
  {
    try
    {
      if(params.action === 'load')
      {
        return Email.Load(params, apiToken);
      }
      else if(params.action === 'renderTemplate')
      {
        return Email.RenderTemplate(params.params, apiToken);
      }
      else if(params.action === 'sendTemplate')
      {
        return Email.SendTemplate(params.params, apiToken);
      }
    }
    catch(err)
    {
      return {error: err.message};
    }
  }



  /**
    Load email model document and email templates
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Load(apiToken)
  {
    try
    {
      let response = await ApiRequest.sendRequest("post", {}, 'email/load', apiToken);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }

  /**
    Load email model document and email templates
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async RenderTemplate(params, apiToken)
  {
    try
    {
      let response = await ApiRequest.sendRequest("post", params, 'email/render-template', apiToken);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }

  /**
    Load email model document and email templates
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async SendTemplate(params, apiToken)
  {
    try
    {
      let response = await ApiRequest.sendRequest("post", params, 'email/send-template', apiToken);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }
}
