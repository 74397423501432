import ApiRequest    from '../../api/request.js';
import BackupIcon from '@material-ui/icons/Backup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import SchemaManager from './SchemaManager';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import SaveIcon from '@material-ui/icons/Save';
import TocIcon from '@material-ui/icons/Toc';
import AddIcon from '@material-ui/icons/Add';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/Remove';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormValidated    from '../../components/Form/FormValidated.js';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FormLoader    from '../../components/Form/FormLoader.js';
import React 		 from 'react';
import TextField from '@material-ui/core/TextField';
import StyledComponent from 'styled-components';
import { Confirm } from "../../components/Confirmation.js";
import DataModifier from '../../utils/DataModifier.js';

// TODO: Make schema fields read from backend so we can centralize changes to this page
export default class ModelManager extends React.Component
{
	// MARK: - Data fields
	_isMounted = false;
	// References
	_schemaForm = null;
	_modelForm = null;
	_css = null;
	// Form inputs
	_typeOptions = [ {text: 'Datetime', value: 'datetime'}, {text: 'Date', value: 'date'}, {text: 'Selection', value: 'select'}, {text: 'File', value: 'file'}, {text: 'Number', value: 'number'}, {text: 'Object', value: 'object'}, {text: 'Reference', value: 'reference'}, {text: 'Secure Text', value: 'secure'}, {text: 'Text', value: 'string'}, {text: 'True/False', value: 'boolean'} ];
	_stringFormInputs = [];
	_referenceFormInputs = [];
	_numberFormInputs = [	];
	_booleanFormInputs = [];
	_fileFormInputs = [];
	_objectFormInputs = [];
	_datetimeFormInputs = [];
	_dateFormInputs = [];
	_selectFormInputs = [];
	_defaultFormInputs = [
		{label: 'Name', id: 'name', element: 'input',	type: 'text', validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
		{label: 'Type', id: 'type', element: 'select', options: this._typeOptions, value: ''}
	];

  // MARK: - Constructor
	constructor(props)
	{
		super(props);

		this.state =
		{
			schemaFormInputs: this._defaultFormInputs,
			selectedSchemaItem: '',

			modelFormInputs: [
				{label: 'Name', id: 'name', element: 'input',	type: 'text', validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Description', id: 'description', element: 'input',	type: 'text', validation: {requirement: 'A description is required', algorithm: 'range', 'min': 1, 'max': 512}, required: 'required', placeholder: 'Enter a description', value: ''},
				{label: '', id: 'submitBtn', 		element: 'input', 	type: 'submit', value: 'Save', onClick: this.createModel, class: 'btn-block  .border .border--white', disabled: true}
			],

			model: null,
			models: null,
			addingModel: false,
			isLoading: false,
			schemaListExpanded: true,
			adminTableSectionExpanded: false,
			permissionSectionExpanded: false,
			tableProperties: '{}',
			permissions: '{}',

			schemaManagerActive: false,
		};

		this._css = this.styleComponent();

		this._schemaForm = React.createRef();
		this._modelForm = React.createRef();

		this.initFormInputs();

    console.log('ModelManager()');
	}

	componentDidMount()
	{
		console.log('ModelManager.componentDidMount()');
		this._isMounted = true;
		this.loadModels();
	}

	initFormInputs()
	{
		this._stringFormInputs = this.getFormInputsForType('string');

		this._secureFormInputs = this.getFormInputsForType('secure');

		this._referenceFormInputs = this.getFormInputsForType('reference');

		this._numberFormInputs = this.getFormInputsForType('number');

		this._booleanFormInputs = this.getFormInputsForType('boolean');

		this._fileFormInputs = this.getFormInputsForType('file');

		this._objectFormInputs = this.getFormInputsForType('object');

		this._datetimeFormInputs = this.getFormInputsForType('datetime');

		this._dateFormInputs = this.getFormInputsForType('date');

		this._selectFormInputs = this.getFormInputsForType('select');
	}

	getFormInputsForType(type)
	{
		var inputs = [];
		switch(type)
		{
			case 'string':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'string'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  		element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Min length', 				id: 'minLength', 		element: 'input', 	type: 'number', 	validation: {requirement: 'Enter zero if you not required', algorithm: 'range', 'min': -9999999999999, 'max': 9999999999999}, required: 'required', placeholder: 'Enter a minimum', value: 0},
				{label: 'Max length', 				id: 'maxLength', 		element: 'input', 	type: 'number', 	validation: {requirement: 'Enter -1 if not required', algorithm: 'range', 'min': -9999999999999, 'max': 9999999999999}, required: 'required', placeholder: 'Enter a maximum', value: 0},
				{label: 'Lowercased', 				id: 'lowercase',   	element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'false'},
				{label: 'Trim whitespace', 		id: 'trim',   			element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'false'},
				{label: 'Unique', 						id: 'unique',   		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'false'},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			case 'select':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'select'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-256 chars', algorithm: 'range', 'min': 0, 'max': 256}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Values', 						id: 'values',  			element: 'input', 	type: 'text', 		validation: {requirement: 'Options are required', algorithm: 'range', 'min': 1, 'max': 256}, required: 'required', placeholder: 'Comma separated values', value: ''},
				{label: 'Unique', 						id: 'unique',   		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'false'},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			case 'secure':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'string'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-256 chars', algorithm: 'range', 'min': 0, 'max': 256}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Min length', 				id: 'minLength', 		element: 'input', 	type: 'number', 	validation: {requirement: 'Enter zero if you not required', algorithm: 'range', 'min': -9999999999999, 'max': 9999999999999}, required: 'required', placeholder: 'Enter a minimum', value: 0},
				{label: 'Max length', 				id: 'maxLength', 		element: 'input', 	type: 'number', 	validation: {requirement: 'Enter -1 if not required', algorithm: 'range', 'min': -9999999999999, 'max': 9999999999999}, required: 'required', placeholder: 'Enter a maximum', value: 0},
				{label: 'Lowercased', 				id: 'lowercase',   	element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'false'},
				{label: 'Trim whitespace', 		id: 'trim',   			element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'false'},
				{label: 'Unique', 						id: 'unique',   		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'false'},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			case 'reference':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, value: 'reference'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-256 chars', algorithm: 'range', 'min': 0, 'max': 256}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Unique', 						id: 'unique',   		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'false'},
				{label: 'Reference', 					id: 'reference',  	element: 'input', 	type: 'text', 		validation: {requirement: 'A reference to another model is required if the type is reference', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter another models name', value: ''},
				{label: 'Key in Reference', 	id: 'keyInReference',element: 'input', 	type: 'text', 		validation: {requirement: 'A key in the reference model is required if the type is reference', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter another models field name', value: ''},
				{label: 'Auto populate', 			id: 'autoPopulate', element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			case 'number':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, value: 'number'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-256 chars', algorithm: 'range', 'min': 0, 'max': 256}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Min value', 				id: 'minLength', 		element: 'input', 	type: 'number', 	validation: {requirement: 'Enter -9999999999999 if you not required', algorithm: 'range', 'min': -9999999999999, 'max': 9999999999999}, required: 'required', placeholder: 'Enter a minimum', value: '-9999999999999'},
				{label: 'Max value', 				id: 'maxLength', 		element: 'input', 	type: 'number', 	validation: {requirement: 'Enter 9999999999999 if not required', algorithm: 'range', 'min': -9999999999999, 'max': 9999999999999}, required: 'required', placeholder: 'Enter a maximum', value: '9999999999999'},
				{label: 'Unique', 						id: 'unique',   		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'false'},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			case 'boolean':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, value: 'boolean'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-256 chars', algorithm: 'range', 'min': 0, 'max': 256}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			case 'file':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, value: 'file'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-256 chars', algorithm: 'range', 'min': 0, 'max': 256}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			case 'object':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, value: 'object'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-256 chars', algorithm: 'range', 'min': 0, 'max': 256}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			case 'datetime':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, value: 'datetime'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-256 chars', algorithm: 'range', 'min': 0, 'max': 256}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			case 'date':
			inputs = [
				{label: 'Name', 							id: 'name',  				element: 'input', 	type: 'text', 		validation: {requirement: 'A name is required', algorithm: 'range', 'min': 1, 'max': 64}, required: 'required', placeholder: 'Enter a name', value: ''},
				{label: 'Type', 							id: 'type',					element: 'select', 	options: this._typeOptions, value: 'date'},
				{label: 'Required', 					id: 'required',  		element: 'select', 	options: [ {text: 'True', value: 'true'}, {text: 'False', value: 'false'} ], validation: { requirement: 'Please choose a value', algorithm: 'select' }, value: 'true'},
				{label: 'Tooltip', 						id: 'tooltip',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a tooltip that is 0-256 chars', algorithm: 'range', 'min': 0, 'max': 256}, placeholder: 'Enter a tooltip (optional)', value: ''},
				{label: 'Tooltip Question', 	id: 'tooltipQuestion',element: 'input', type: 'text', validation: {requirement: 'Enter a tooltip question that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a tooltip question (optional)', value: ''},
				{label: 'Tooltip type', 			id: 'tooltipType',  element: 'select', 	options: [ {text: 'On label', value: 'onlabel'}, {text: 'Side Button', value: 'sidebutton'} ], validation: { requirement: 'Tooltip type is optional if no tooltip', algorithm: 'select' }, value: ''},
				{label: 'Label', 							id: 'label',  			element: 'input', 	type: 'text', validation: {requirement: 'Enter a label that is 0-64 chars', algorithm: 'range', 'min': 0, 'max': 64}, placeholder: 'Enter a label (optional)', value: ''},
				{label: 'Managed Update Form',id: 'managedUpdateForm', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: 'Is Array',id: 'isArray', element: 'input', type: 'checkbox', validation: {algorithm: 'checkbox'}, required: '', checked: false, value: 'false'},
				{label: '', id: 'cancelBtn',	element: 'input', 	type: 'submit', value: 'Cancel', 	onClick: this.clearSchemaField, class: 'btn-block  .border .border--white', disabled: true},
				{label: '',	id: 'submitBtn', 	element: 'input', 	type: 'submit', value: 'Save', 		onClick: this.addSchemaField, 	class: 'btn-block  .border .border--white', disabled: true}
			];
			break;

			default:
			inputs = [];
		}

		console.log(inputs);
		return inputs;
	}

	// MARK: - APIs
	loadModels = async() =>
	{
		console.log('ModelManager.loadModels()');
		this.setState({ isLoading: true });

		var params = { params: {} };

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "model/query-model-collection", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);
			this.setState({
				isLoading: false,
				models: response.data.results
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	createModel = async() =>
	{
		console.log('ModelManager.createModel()');
		this.setState({ isLoading: true });

		var params =
		{
			params: { },
			name: this.state.name,
			description: this.state.description,
		};
		try
		{
			var response = await ApiRequest.sendRequest("post", params, "model/create-model", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);

			var models = await DataModifier.sortedInsert(response.data.results, this.state.models, 'name');
			this.setState({
				isLoading: false,
				addingModel: false,
				models: models,
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	/**
		Schema item selected from list, populate form values
		@param 	{SchemaItem}	item 		Schema item
	*/
	schemaItemSelected = (item) =>
	{
		console.log('ModelManager.schemaItemSelected(' + item.name + ')');
		if(item !== this.state.selectedSchemaItem)
		{
			console.log(item);
			// Iterate form inputs and update value for selected model values
			var formInputs = this.getFormInputsForType(item.type);

			// Populate form input values
			for(var i = 0; i < formInputs.length; i++)
			{
				if(formInputs[i].type !== 'submit')
				{
					formInputs[i].value = (item[formInputs[i].id] === undefined && formInputs[i].type === 'checkbox' ? false : item[formInputs[i].id]);
				}
			}

			/*
				Update form inputs
				Form input uses it's state's value
				and since we're not instantiating a new object
				we need to tell the form to update it's value
			*/
			console.log('New value: ' + formInputs[0].value);
			console.log(formInputs);
			this.setState({ selectedSchemaItem: item, schemaFormInputs: formInputs, permissionSectionExpanded: false });
		}
		// Already selected, de-select this schema field
		else
		{
			this.setState({ selectedSchemaItem: '', schemaFormInputs: this._defaultFormInputs });
		}
 	};



	modelItemSelected = (item) =>
	{
		this.setState(
		{
			model: item,
			'model-name': item.name,
			'model-description': item.description,
			tableProperties: JSON.stringify(item.tableProperties, null, 4),
			permissions: JSON.stringify(item.permissions, null, 4)
		});
	}

	modelFormOnChange = (change, isFormValid) =>
	{
		this.setState({ [change.id.substr(change.id.lastIndexOf('.') + 1)]: change.value });
	}

	schemaFormOnChange = (change, isFormValid) =>
	{
		console.log('ModelManager.schemaFormOnChange(' + JSON.stringify(change) + ')');
		var formInputs = null;

		// Handle adjusting required form inputs based on type of field
		if(change.id === 'type')
		{
			switch(change.value)
			{
				case 'string':
					formInputs = this._stringFormInputs;
					break;
				case 'select':
					formInputs = this._selectFormInputs;
					break;
				case 'secure':
					formInputs = this._secureFormInputs;
					break;
				case 'reference':
					formInputs = this._referenceFormInputs;
					break;
				case 'number':
					formInputs = this._numberFormInputs;
					break;
				case 'boolean':
					formInputs = this._booleanFormInputs;
					break;
				case 'file':
					formInputs = this._fileFormInputs;
					break;
				case 'object':
					formInputs = this._objectFormInputs;
					break;
				case 'datetime':
					formInputs = this._datetimeFormInputs;
					break;
				case 'date':
					formInputs = this._dateFormInputs;
					break;
				default:
					return;
			}

			// Save name
			var name = "";
			for(var i = 0; i < this.state.schemaFormInputs.length; i++)
			{
				if(this.state.schemaFormInputs[i].id === 'name')
				{
					name = this.state.schemaFormInputs[i].value;
					break;
				}
			}

			// Update name in new form inputs
			for(var i = 0; i < formInputs.length; i++)
			{
				if(formInputs[i].id === 'name')
				{
					formInputs[i].value = name;
					break;
				}
			}
		}

		// Not changing type, update value in current form inputs
		else
		{
			formInputs = this.state.schemaFormInputs;
			for(var i = 0; i < formInputs.length; i++)
			{
				if(formInputs[i].id === change.id)
				{
					formInputs[i].value = change.value;
					console.log('TypeOnChange: ' + formInputs[i].id);
					console.log(change.value);
					break;
				}
			}
		}

		this.setState({ schemaFormInputs: formInputs });
	}

	// MARK: - Top buttons
	// Update core model name/description
	saveOnClick = async() =>
	{
		console.log('ModelManager.saveOnClick()');
		this.setState({ isLoading: true });

		let params =
		{
			modelName: this.state.model.name,
			newName: this.state['model-name'],
			newDescription: this.state['model-description'],
			tableProperties: this.state.tableProperties,
			permissions: this.state.permissions
		}

		console.log(params);

		try
		{
			// Update model
			var response = await ApiRequest.sendRequest("post", params, "model/update-model", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
				return;
			}
			console.log(response.data);

			// Update models array
			var index = this.findModelIndexByName(this.state.model.name);
			var models = this.state.models;
			models[index] = response.data.results;

			this.setState({ model: response.data.results, models: models, isLoading: false });
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	// Stop editing model
	quitOnClick = async() =>
	{
		this.setState({ model: null, schemaFormInputs: this._defaultFormInputs });
	}

	backupOnClick = async() =>
	{
		console.log('ModelManager.backupOnClick()');
		this.setState({ isLoading: true });
		let params = {};
		try
		{
			var response = await ApiRequest.sendRequest("post", params, "model/backup", this.props.cookies.get('token'));
			console.log(response.data);
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
				return;
			}
			this.setState({ isLoading: false });
			this.props.showAlert(true, '', 'Schema and all records backed up', 'success');
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	// MARK: - Bottom buttons
	addSchemaField = async(evt) =>
	{
		evt.preventDefault();

		console.log('ModelManager.addSchemaField()');
		console.log(this.state.schemaFormInputs);

		this.setState({ isLoading: true });

		var params =
		{
			name: '',
			type: '',
			required: false,
			minLength: -1,
			maxLength: -1,
			lowercase: false,
			trim: false,
			unique: false,
			reference: '',
			autoPopulate: false,
			keyInReference: '',
			values: '',
			modelName: this.state.model.name
		};

		if(this.state.selectedSchemaItem != '')
		{
			params.id = this.state.selectedSchemaItem._id;
		}

		for(var i = 0; i < this.state.schemaFormInputs.length; i++)
		{
			// Skip buttons as they have no label
			if(this.state.schemaFormInputs[i].label != '')
			{
				params[this.state.schemaFormInputs[i].id] = this.state.schemaFormInputs[i].value;
			}
		}
		console.log(params);

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "model/upsert-schema-field", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);

			// Put schema field on model and put that model back into list
			var models = this.state.models;
			for(var i = 0; i < models.length; i++)
			{
				if(models[i].name === this.state.model.name)
				{
					// Adding new schema field
					if(this.state.selectedSchemaItem === '')
					{
							models[i].schemaFields.push(response.data.results);
					}
					else // Updating existing schema field
					{
						for(var j = 0; j < models[i].schemaFields.length; j++)
						{
							if(this.state.selectedSchemaItem._id === models[i].schemaFields[j]._id)
							{
								models[i].schemaFields[j] = response.data.results;
								break;
							}
						}
					}

					break;
				}
			}

			// Adding new schema field
			// Clear form after
			if(this.state.selectedSchemaItem === '')
			{
				this.setState({
					isLoading: false,
					addingModel: false,
					models: models,
					selectedSchemaItem: '',
					schemaFormInputs: this._defaultFormInputs
				});
			}
			else // Updating schema field, don't clear form
			{
				this.setState({
					isLoading: false,
					addingModel: false,
					models: models,
					selectedSchemaItem: response.data.results
				});
			}
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	clearSchemaField = async() =>
	{
		this.setState({ selectedSchemaItem: '', schemaFormInputs: this._defaultFormInputs });
	}

	deleteModelOnClick = async() =>
	{
		let message = 'Are you sure you want to delete the model for ' + this.state.model.name;
		let confirmed = await Confirm(message);
		if(confirmed)
		{
			console.log('ModelManager.deleteModel()');
			this.setState({ isLoading: true });

			var params = { name: this.state.model.name };
			try
			{
				var response = await ApiRequest.sendRequest("post", params, "model/delete-model-collection", this.props.cookies.get('token'));
				if(response.data.error !== null)
				{
					this.setState({ isLoading: false });
					this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
					return;
				}

				console.log(response.data.results);

				var models = this.state.models;
				for(var i = 0; i < models.length; i++)
				{
					if(models[i].name === this.state.model.name)
					{
						models.splice(i, 1);
						break;
					}
				}
				this.setState({
					isLoading: false,
					addingModel: false,
					models: models,
					model: null,
				});
			}
			catch(err)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}
	}

	// MARK: - Schema field buttons
	deleteSchemaFieldOnClick = async() =>
	{
		console.log('ModelManager.deleteSchemaFieldOnClick()');
		let message = 'Are you sure you want to delete the schema field for ' + this.state.selectedSchemaItem.name;
		let confirmed = await Confirm(message);
		if(confirmed)
		{
			console.log('ModelManager.deleteModel()');
			this.setState({ isLoading: true });

			var params =
			{
				modelName: this.state.model.name,
				id: this.state.selectedSchemaItem._id
			};
			try
			{
				var response = await ApiRequest.sendRequest("post", params, "model/delete-schema-field", this.props.cookies.get('token'));
				if(response.data.error !== null)
				{
					this.setState({ isLoading: false });
					this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
					return;
				}

				console.log(response.data.results);
				this.setState({
					isLoading: false,
					model: response.data.results
				});
			}
			catch(err)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}
	}


	// MARK: - Model form
	addNewModelOnClick = async() =>
	{
		this.setState({ addingModel: true });
	}

	removeNewModelOnClick = async() =>
	{
		this.setState({ addingModel: false });
	}

	// MARK: - Helpers
	findModelIndexByName = (name) =>
	{
		var models = this.state.models;
		for(var i = 0; i < models.length; i++)
		{
			if(models[i].name === name)
			{
				return i;
			}
		}
		return -1;
	}

	// MARK: - Render
  render()
  {
    console.log('ModelManager.render()');

		var modelList = [];
		if(this.state.models && this.state.model === null)
		{
			this.state.models.forEach( (model, index) =>
			{
				modelList.push(<ListItem key={`model-list-item${index}`} button onClick={ () => { this.modelItemSelected(model); } }>
					<ListItemText primary={model.name} />
				</ListItem>);
			});
		}

    return(
			<this._css>
				<FormLoader isLoading={this.state.isLoading}/>

				{this.state.schemaManagerActive ? (
					<>
						<Button
							color="secondary"
							startIcon={<ExitToAppIcon />}
							onClick={ () => this.setState({ schemaManagerActive: false }) }
						>Back to Model Manager</Button>
						<SchemaManager
							{...this.props}
						/>
					</>
				) : (
					<Grid container spacing={1}>

						<Grid item xs={1}></Grid>

						<Grid item xs={2}>
							{this.state.model !== null &&
							<>
								<Grid item xs={12} className='main-btn-section'>
									<Button
										color="primary"
										startIcon={<SaveIcon />}
										onClick={ () => this.saveOnClick() }
									>Save</Button>
									<Button
										color="secondary"
										startIcon={<ExitToAppIcon />}
										onClick={ () => this.quitOnClick() }
									>Quit</Button>
								</Grid>

								<Grid item xs={12}>
									<TextField
										id="outlined-basic"
										label="Name"
										variant="outlined"
										className='schema-name'
										onChange={ (e) => { this.setState({'model-name': e.target.value}); } }
										value={this.state['model-name']}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="outlined-basic"
										label="Description"
										variant="outlined"
										className='schema-name'
										onChange={ (e) => { this.setState({'model-description': e.target.value}); } }
										value={this.state['model-description']}
									/>
								</Grid>

								<Grid item xs={12} className='schema-list'>
									<Button
										color="primary"
										startIcon={<UnfoldMoreIcon />}
										onClick={() => { this.setState({ schemaListExpanded: !this.state.schemaListExpanded, adminTableSectionExpanded: false, permissionSectionExpanded: false }); }}
									>{this.state.schemaListExpanded ? 'Hide schema items' : 'Show schema items'}</Button>

									{this.state.model && this.state.schemaListExpanded && this.state.model.schemaFields.map( (item, index) =>
									{
										return (
										<Grid item xs={12} key={`schema-list-item${index}`}>
											<Grid item xs={12}>
												<Chip
													label={item.name}
													clickable
													color="primary"
													icon={this.state.selectedSchemaItem === item ? <DoneIcon /> : null}
													variant="outlined"
													onClick={ () => this.schemaItemSelected(item) }
												/>
											</Grid>
											{this.state.selectedSchemaItem === item &&
											<Grid item xs={12} className='schema-btns'>
												<Grid item xs={12}>
													<Button color="primary" startIcon={<FileCopyIcon />}
													>Copy Field</Button>
													<Button color="primary" startIcon={<DeleteIcon />}
														onClick={ () => this.deleteSchemaFieldOnClick() }
													>Delete Field</Button>
												</Grid>
											</Grid>}
										</Grid>
										);
									})}

									<Grid item xs={12}>
										<Button
											className='table-properties-btn'
											color="primary"
											startIcon={<TocIcon />}
											onClick={() => { this.setState({ adminTableSectionExpanded: !this.state.adminTableSectionExpanded }); }}
										>{this.state.adminTableSectionExpanded ? 'Hide Admin Table Properties' : 'Edit Admin Table Properties'}</Button>
									</Grid>

									<Grid item xs={12}>
										<Button
											className='table-properties-btn'
											color="primary"
											startIcon={<TocIcon />}
											onClick={() => { this.setState({ permissionSectionExpanded: !this.state.permissionSectionExpanded }); }}
										>{this.state.permissionSectionExpanded ? 'Hide Access Permissions' : 'Edit Access Permissions'}</Button>
									</Grid>

								</Grid>
								<Grid item xs={12} className='bottom-btn-section'>
									<Button
										color="default"
										startIcon={<DeleteIcon />}
										onClick={ () => this.deleteModelOnClick() }
									>Delete Model</Button>
								</Grid>
							</>}

							{this.state.model === null && !this.state.addingModel &&
								<Grid item xs={12} className="model-list">
									<h1>Models <AddIcon onClick={ () => this.addNewModelOnClick() }/></h1>
									<Paper>
										<List component="nav" aria-label="model list">
											{modelList}
										</List>
									</Paper>
									<Button
										color="primary"
										startIcon={<BackupIcon />}
										onClick={ () => this.backupOnClick() }
									>Backup To Cloud</Button>
									<Button
										color="success"
										startIcon={<FormatListNumberedIcon />}
										onClick={ () => this.setState({ schemaManagerActive: true }) }
									>Switch to Schema Manager</Button>
								</Grid>}

							{this.state.model === null && this.state.addingModel &&
								<Grid item xs={12} className="model-list">
									<h2>New Model <RemoveIcon onClick={ () => this.removeNewModelOnClick() }/></h2>
									<Paper>
										<FormValidated
											ref={this._modelForm}
											formInputs={this.state.modelFormInputs}
											formOnChange={this.modelFormOnChange}
											dynamicInputs={false}
											showErrorList={false}
											validateOnInit={false}
											siteManager={null}
											columns={1}
										/>
									</Paper>
								</Grid>
							}
						</Grid>

						<Grid item xs={1}></Grid>

						{this.state.model !== null &&
						!this.state.adminTableSectionExpanded &&
						!this.state.permissionSectionExpanded &&
						<Grid item xs={7} className="schema-form">
							<FormValidated
								ref={this._schemaForm}
								formInputs={this.state.schemaFormInputs}
								formOnChange={this.schemaFormOnChange}
								dynamicInputs={true}
								showErrorList={false}
								validateOnInit={false}
								siteManager={null}
								columns={2}
							/>
						</Grid>}

						{this.state.model !== null &&
						this.state.adminTableSectionExpanded &&
						!this.state.permissionSectionExpanded &&
						<Grid item xs={7} className="admin-table-properties">
							<TextField
								id="outlined-basic"
								label="Table Properties"
								variant="outlined"
								multiline
								className='table-properties'
								rows={18}
								onChange={ (e) => { this.setState({ 'tableProperties' : e.target.value }); } }
								value={this.state['tableProperties']}
							/>
							<Button
								color="default"
								startIcon={<FormatIndentIncreaseIcon />}
								onClick={ () => { console.log('TODO!!!');  }}
							>Format</Button>
						</Grid>}

						{this.state.model !== null &&
						!this.state.adminTableSectionExpanded &&
						this.state.permissionSectionExpanded &&
						<Grid item xs={7} className="admin-table-properties">
							<TextField
								id="outlined-basic"
								label="Table Properties"
								variant="outlined"
								multiline
								className='table-properties'
								rows={18}
								onChange={ (e) => { this.setState({ 'permissions' : e.target.value }); } }
								value={this.state['permissions']}
							/>
							<Button
								color="default"
								startIcon={<FormatIndentIncreaseIcon />}
								onClick={ () => { console.log('TODO!!!');  }}
							>Format</Button>
						</Grid>}

						<Grid item xs={1}></Grid>

					</Grid>
				)}
			</this._css>
    );
  }


	styleComponent = (siteManager) =>
	{
		return StyledComponent.div`
			.main-btn-section, .bottom-btn-section
			{
				margin-bottom: 25px;
				margin-top: 8px;
				justify-content: space-between;
		    display: flex;
			}
			.bottom-btn-section
			{
				margin-bottom: 0px;
				margin-top: 50px;
				justify-content: space-between;
		    display: flex;
			}
			.schema-name
			{
				width: 100%;
				margin-top: 10px;
			}
			.schema-list
			{
				margin-top: 25px;
			}
			.table-properties-btn
			{
				margin-top: 15px;
			}
			.admin-table-properties
			{
				margin-top: 15px;
				width: 100%;
			}
			.table-properties
			{
				margin-top: 15px;
				width: 100%;
				font-size: 10px;

				textarea
				{
					white-space: pre-line;
				}
			}
			.schema-btns
			{
				margin-bottom: 10px;
			}
			.schema-form
			{
				margin-top: 15px;
			}
			.model-list
			{
				margin-top: 15px;

				h1
				{
					text-align: left;
				}
				h2
				{
					text-align: left;
					color: black;
				}
			}
			.MuiChip-outlinedPrimary
			{
				width: 100%;
				margin-top: 10px;
			}
		`;
	}
}
