import Grid from '@material-ui/core/Grid';
import React 		 from 'react';
import StyledComponent from 'styled-components';

import ApplySelectionButton from '../../components/Table/ApplySelectionButton';
import ApiRequest    from '../../api/request.js';
import { FormLoader, FormValidated } from '../../components/Form';
import Table from '../../components/Table/Table';
import DataModifier from '../../utils/DataModifier';

const TABLE_HEADERS = [
	{
		id: "type",
		filter: true,
		label: "Type"
	},
	{
		id: "description",
		filter: true,
		label: "Description"
	},
	{
		id: "data.model",
		filter: true,
		label: "Model"
	},
	{
		id: "data.schemaField",
		filter: false,
		label: "Schema Field",
		isObject: true
	},
	{
		id: "data.changes",
		filter: false,
		label: "Changes",
		isObject: true
	},
];

const SCHEMA_FIELDS = [
	{
    autoPopulate: false,
    isArray: false,
    keyInReference: "",
    label: "Type",
    lowercase: false,
    maxLength: 128,
    minLength: 0,
    name: "type",
    trim: true,
    type: "string",
    unique: false,
	},
	{
		autoPopulate: false,
		isArray: false,
		keyInReference: "",
		label: "Description",
		lowercase: false,
		maxLength: 128,
		minLength: 0,
		name: "description",
		trim: true,
		type: "string",
		unique: false,
	},
	{
		autoPopulate: false,
		isArray: false,
		keyInReference: "",
		label: "Model",
		lowercase: false,
		maxLength: 128,
		minLength: 0,
		name: "model",
		trim: true,
		type: "string",
		unique: false,
	},
	{
		autoPopulate: false,
		isArray: false,
		keyInReference: "",
		label: "Schema field",
		lowercase: false,
		maxLength: 128,
		minLength: 0,
		name: "schemaField",
		trim: false,
		type: "object",
		unique: false,
	},
	{
		autoPopulate: false,
		isArray: true,
		keyInReference: "",
		label: "Changes",
		lowercase: false,
		maxLength: 128,
		minLength: 0,
		name: "changes",
		trim: false,
		type: "object",
		unique: false,
	}
]

const REFRESH_QA_BACKUP = true;

export default class SchemaManager extends React.Component
{
	// MARK: - Data fields
	_isMounted = false;
	// References
	_schemaForm = null;
	_css = null;
	_table = null;

  // MARK: - Constructor
	constructor(props)
	{
		super(props);

		this.state =
		{
			stepOneFormInputs: [
				{label: 'Backend URL', 	id: 'backendUrl', element: 'input',	type: 'text', validation: {requirement: 'A backend URL is required', 	algorithm: 'range', 'min': 5, 'max': 128}, required: 'required', placeholder: 'http://backend.com/', value: process.env.REACT_APP_API_SERVER_URL},
				{label: 'Secret Key', 	id: 'secretKey', element: 'input',	type: 'text', validation: {requirement: 'A secret key is required', 	algorithm: 'range', 'min': 5, 'max': 128}, required: 'required', placeholder: 's3cr3t', value: ''},
				{label: '', id: 'submitBtn', 		element: 'input', 	type: 'submit', value: 'Find Differences', onClick: this.getDifferences, class: 'btn-block  .border .border--white', disabled: false},
			],
			differences: [],
			isLoading: false,
		};

		this._css = this.styleComponent();

		this._schemaForm = React.createRef();
		this._table = React.createRef();

    console.log('SchemaManager()');
	}

	componentDidMount()
	{
		console.log('SchemaManager.componentDidMount()');
		this._isMounted = true;
	}

	// MARK: - APIs
	getDifferences = async(evt) =>
	{
		evt.preventDefault();

		console.log('SchemaManager.getDifferences()');
		this.setState({ isLoading: true });

		const inputKeys = this.state.stepOneFormInputs.map(input => input.id);
		const backendUrlIdx = inputKeys.indexOf('backendUrl');
		const secretKeyIdx = inputKeys.indexOf('secretKey');

		var params =
		{
			secretKey: this.state.stepOneFormInputs[secretKeyIdx].value,
			skipDocuments: true,
		};

		try
		{
			// Tell DV to initiate backup
			// @todo make this function something that can be controlled via checkbox on page
			if(REFRESH_QA_BACKUP)
			{
				const backupResults = await ApiRequest.sendExternalRequest({
					url: this.state.stepOneFormInputs[backendUrlIdx].value,
					method: "post",
					params: params,
					path: "model/backup",
					token: this.props.cookies.get('token'),
				});

				if(backupResults.data.error !== null)
				{
					this.setState({ isLoading: false });
					this.props.showAlert(true, 'Un-oh', backupResults.data.error, 'danger');
					return;
				}
			}

			// Tell current ENV to read back up and give us a list of differences
			const differencesResults = await ApiRequest.sendRequest(
				"post",
				{},
				"model/differences",
				this.props.cookies.get('token')
			);

			if(differencesResults.data.error)
			{
				console.error(differencesResults.data.error);
				throw new Error(differencesResults.data.error);
			}

			console.log(differencesResults.data.results);
			this.setState({
				isLoading: false,
				differences: differencesResults.data.results
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true,
				'Un-oh',
				err.message,
				'danger');
		}
	}

	applyDifferences = async({ differenceIds }) =>
	{
		console.log('SchemaManager.createModel()');
		this.setState({ isLoading: true });

		const differences = this.state.differences.filter(
			difference => differenceIds.indexOf(difference._id) !== -1
		);

		const params =
		{
			differences
		};
		try
		{
			const response = await ApiRequest.sendRequest(
				"post",
				params,
				"model/apply-differences",
				this.props.cookies.get('token')
			);
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);

			const updatedDifferences = await DataModifier.filterObjectsOutOfLhs({
				lhs: [...this.state.differences],
				rhs: differences,
				key: '_id'
			});
			this.setState({
				isLoading: false,
				differences: updatedDifferences,
			});
			this._table.current.clearAll();
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	schemaFormOnChange = (change, isFormValid) =>
	{
		const formInputs = [...this.state.stepOneFormInputs];
		for(var i = 0; i < formInputs.length; i++)
		{
			if(formInputs[i].id === change.id)
			{
				formInputs[i].value = change.value;
				//console.log('TypeOnChange: ' + formInputs[i].id);
				//console.log(change.value);
				break;
			}
		}
		this.setState({ stepOneFormInputs: formInputs });
	}

	// MARK: - Render
  render()
  {
    console.log('SchemaManager.render()');
		//console.log(this.state);

    return(
			<this._css>
				<FormLoader isLoading={this.state.isLoading}/>
	      <Grid container spacing={1}>

					{/* Schema field form */}
					<Grid item xs={7} className="schema-form">
						<FormValidated
							ref={this._schemaForm}
							formInputs={this.state.stepOneFormInputs}
							formOnChange={this.schemaFormOnChange}
							dynamicInputs={true}
							showErrorList={false}
							validateOnInit={false}
							siteManager={null}
							columns={2}
						/>
					</Grid>

					{this.state.differences.length > 0 &&
					<div className={'data-manager-large-table'} style={{ marginBottom: '25px', width: '100%' }}>
						<Table
							ref={this._table}
							showAlert={this.props.showAlert}
							data={this.state.differences}
							headers={TABLE_HEADERS}
							model={null}
							schemaFields={SCHEMA_FIELDS}

							selectEnabled={true}
							selectAllEnabled={true}
							multiSelectEnabled={true}

							defaultSortOrder={'desc'}
							defaultSort={'model'}
							sortEnabled={true}

							tableDidStartLoading={(action) => console.log(action)}
							tableDidFinishLoading={(action, message, error) => console.log(action)}

							title={'Differences'}
							titleIcon={'cil-notes'}

							isDeleteAvailable={false}

							updateForm={{
								isEnabled: false
							}}
							updateFormInputs={[]}

							createFormInputs={[]}
							createForm={{
								isEnabled: false,
							}}

							viewForm={{}}
							
							isCsvAvailable={true}

							paginationEnabled={true}

							cookies={null}
							siteManager={null}

							customButton1AllowMultiple
							customButton1={({ data, cookies, tableDidStartLoading, tableDidFinishLoading }) =>
		          {
		            return (
		              <ApplySelectionButton
		                data={data}
		                cookies={cookies}
		                tableDidStartLoading={tableDidStartLoading}
		                tableDidFinishLoading={tableDidFinishLoading}
		                onClick={() =>
		                {
		                  this.applyDifferences({ differenceIds: data });
		                }}
		              />
		            )
		          }}

							layout={2}
						/>
					</div>}
	      </Grid>
			</this._css>
    );
  }


	styleComponent = (siteManager) =>
	{
		return StyledComponent.div`
			.bottom-btn-section
			{
				margin-bottom: 0px;
				margin-top: 50px;
				justify-content: space-between;
		    display: flex;
			}
			.schema-form
			{
				margin-top: 15px;
			}
			.MuiChip-outlinedPrimary
			{
				width: 100%;
				margin-top: 10px;
			}
			.data-manager-large-table: {
				width: 100%;
			}
		`;
	}
}
