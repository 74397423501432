import ApiRequest  from '../api/request.js';


export default class Data
{
  /**
    Interact with data routes
    @note Parameters come in an object named params and may contain more than what is just labeled here
    look at actual method called for action to determine actual parameters
    @param {String}  params.model is the model type being interacted
    @param  {String}  params.action   Is the action to take (create|delete|update)
    @param  {String}  apiToken  Token so we can talk to backend
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Interact(params, apiToken)
  {
    try
    {
      if(params.action === 'create')
      {
        return Data.Create(params, apiToken);
      }
      if(params.action === 'delete')
      {
        return Data.Delete(params, apiToken);
      }
      else if(params.action === 'query')
      {
        return Data.Query(params, apiToken);
      }
      else if(params.action === 'update')
      {
        return Data.Update(params, apiToken);
      }
    }
    catch(err)
    {
      return {error: err.message};
    }
  }



  /**
    Create new model document
    @note Parameters come in an object named params
    @param {JSON}  params.params  is the parameters to create with
    @param {String}  params.model is the model type being created
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Create(params, apiToken)
  {
    console.log('\t\tData.Create()');
    try
    {
      // Convert to form data
      const formData = new FormData();
      const paramKeys = Object.keys(params.params);
      for(var i = 0; i < paramKeys.length; i++)
      {
        // TODO: Make this somehow check the schema fields and make sure it knows what types are files
        if(paramKeys[i] !== 'files')
        {
          formData.set(paramKeys[i], params.params[paramKeys[i]]);
        }
        else
        {
          const fileKeys = Object.keys(params.params[paramKeys[i]]);
          if(fileKeys.length > 0)
          {
            for(let j = 0; j < fileKeys.length; j++)
            {
              formData.append(paramKeys[i], params.params[paramKeys[i]][fileKeys[j]]);
            }
          }
        }
      }
      formData.set('model', params.model);

      let response = await ApiRequest.sendRequest("post", formData, 'data/' + params.action, apiToken, 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');
      console.log(response.data);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }

  /**
    Delete model document
    @note Parameters come in an object named params
    @param {String}  params.model is the model type being deleted
    @param  {String}  params.id   The ID of the document to delete
    @returns  {JSON} response from server
  */
  static async Delete(params, apiToken)
  {
    try
    {
      const deleteParams =
      {
        model: params.model,
        id: params.id
      };
      let response = await ApiRequest.sendRequest("post", deleteParams, 'data/' + params.action, apiToken);
      //console.log(response.data);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }

  /**
    Query model document
    @note Parameters come in an object named params
    @param {JSON}  params.params  is the parameters to create with
    @param {String}  params.model is the model type being created
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Query(params, apiToken)
  {
    console.log(params);
    try
    {
      const queryParams =
      {
        model: params.model,
        params: params.params
      };
      let response = await ApiRequest.sendRequest("post", queryParams, 'data/' + params.action, apiToken);
      //console.log(response.data);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }

  /**
    Update model document
    @note Parameters come in an object named params
    @param {JSON}  params.params  is the parameters to create with
    @param {String}  params.model is the model type being created
    @param  {String}  params.id   Is the id of the document being updated
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Update(params, apiToken)
  {
    console.log('\t\tData.Update()');
    try
    {
      // Convert to form data
      const formData = new FormData();
      const paramKeys = Object.keys(params.params);
      for(var i = 0; i < paramKeys.length; i++)
      {
        // TODO: Make this somehow check the schema fields and make sure it knows what types are files
        if(paramKeys[i] !== 'files')
        {
          formData.set(paramKeys[i], params.params[paramKeys[i]]);
        }
        else
        {
          const fileKeys = Object.keys(params.params[paramKeys[i]]);
          if(fileKeys.length > 0)
          {
            for(let j = 0; j < fileKeys.length; j++)
            {
              formData.append(paramKeys[i], params.params[paramKeys[i]][fileKeys[j]]);
            }
          }
        }
      }
      formData.set('model', params.model);
      formData.set('id', params.id);

      let response = await ApiRequest.sendRequest("post", formData, 'data/' + params.action, apiToken, 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');
      console.log(response.data);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }
}
