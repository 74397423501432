import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';

class ApplySelectionButton extends React.Component
{
  render()
  {
    return (
      <Tooltip title="Apply selected items">
        <IconButton onClick={async () =>
          {
            console.log('ApplySelectionButton.onClick()');
            this.props.onClick();
          }} aria-label="row">
          <CheckBoxIcon />
        </IconButton>
      </Tooltip>
    );
  }
}


export default ApplySelectionButton;
