import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StyledComponent from 'styled-components';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';

import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { AppText } from '../constant';
import { ApiManager }  from '../managers';

const StyledReport = StyledComponent.div`
min-height: 80%;
overflow-y: scroll;
padding: 15px 15px;

.friends-content
{
  text-align: left;
}
.friends-title
{
  color: rgb(35, 83, 115);
}
.preview-title
{
  color: rgb(35, 83, 115);
}
.create-form
{
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-control
{
  width: 100%

  @media(min-width: 600px)
  {
    width: 55%;
  }
}
.print-btn {
  margin-top: 100px;
}
.select-label
{
  width: 100%

  @media(min-width: 600px)
  {
    width: 55%;
  }
}
.selector
{
  width: 100%

  @media(min-width: 600px)
  {
    width: 55%;
  }
}
.main-btn-section
{
  align-self: flex-end;
}
.bottom-section
{
  flex-direction: row;
}
.info-message
{
  white-space:pre-wrap;
}`;

const STEPS = [
  'Go to the "Configuration" page and verify email configurations. To send emails to users make sure "EMAIL_SENDING_ENABLED" is true and "EMAIL_TEST_MODE" is false.',
  'Go to the "Jobs" screen and "Purge Logs" to make room in the database for new batch job logs. If you want to save these before purging them there is an export to CSV button at the top of the screen.',
  'Go to the "Events" screen, verify all other events are set to "finished" status.',
  'Add your new event, make sure the start and end dates are set correctly.',
  'Go back to the "Jobs" screen, toggle on the "Use suggested batch schedule" check box.'
];

function Reports({ showAlert }) {

  return (
    <Paper>
      <StyledReport>
        <DialogTitle disableTypography>
          <Typography
            variant="h3"
            classes={{h3: 'friends-title'}}
            display='block'
            align={'center'}
          >{'Checklist for adding a new event'}</Typography>
        </DialogTitle>
        <DialogContent /*dividers*/ className='friends-content'>
          <Grid container direction="row">
            <Grid item xs={12}>
              {STEPS.map((step, i) => (
                <Grid key={i} container direction="row" style={{marginTop: '25px', marginBottom: '25px'}}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      classes={{h5: 'preview-title'}}
                      display='block'
                      align={'left'}
                    >{i + 1}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      classes={{body2: 'info-message'}}
                      align={'left'}
                    >{step}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
           </Grid>
        </DialogContent>
      </StyledReport>
    </Paper>
  );
}

export default Reports;