import ApiRequest  from '../api/request.js';


export default class Chat
{
  /**
    Interact with chat routes
    @note Parameters come in an object named params and may contain more than what is just labeled here
    look at actual method called for action to determine actual parameters
    @param {String}  params.model is the model type being interacted
    @param  {String}  params.action   Is the action to take (message/conversations)
    @param  {String}  apiToken  Token so we can talk to backend
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Interact(params, apiToken)
  {
    try
    {
      if(params.action === 'message')
      {
        return Chat.Message(params, apiToken);
      }
    }
    catch(err)
    {
      return {error: err.message};
    }
  }



  /**
    Create a message
    @note Parameters come in an object named params
    @param {JSON}  params.params  is the parameters to create with
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Message(params, apiToken)
  {
    console.log('\t\tChat.Message()');
    try
    {
      // Convert to form data
      const formData = new FormData();
      const paramKeys = Object.keys(params.params);
      for(var i = 0; i < paramKeys.length; i++)
      {
        // TODO: Make this somehow check the schema fields and make sure it knows what types are files
        /*if(paramKeys[i] !== 'files')
        {*/
          formData.set(paramKeys[i], params.params[paramKeys[i]]);
        /*}
        else
        {
          const fileKeys = Object.keys(params.params[paramKeys[i]]);
          if(fileKeys.length > 0)
          {
            for(let j = 0; j < fileKeys.length; j++)
            {
              formData.append(paramKeys[i], params.params[paramKeys[i]][fileKeys[j]]);
            }
          }
        }*/
      }

      let response = await ApiRequest.sendRequest("post", formData, 'chat/' + params.action, apiToken, 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');
      console.log(response.data);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }
}
