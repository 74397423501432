import React from 'react';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import TimePicker from 'rc-time-picker';
import Tooltip from '@material-ui/core/Tooltip';
import ApiRequest    from '../../api/request.js';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import DatePicker from 'react-datepicker';
import Moment from 'moment';
const format = 'h:mm a';
const now = Moment().hour(0).minute(0);

function Requirement(props)
{
    var className = 'requirement' + (props.isHidden === true ? '' : '-showing');
    return (<div className={className}>{props.text}</div>);
}

/**
  Input field for validated form
  @param  {function}    isFormValid         Callback to form so we can check if entire form is valid
  @param 	{function} 	  formInputOnChange   Callback when a form value changes
  @param  {String}        rowClassName      Class name of forminput container (used for creating new rows and stuff)
  @param  {bool}        errorMessage        Indicates if form field is in error state (empty if not) otherwise holds message
  @param	{JSON}	     data                 JSON object representing form input
    label: 		 Label text to show above input
    id:			   ID of inputs so it can be accessed by parent later
    type: 		 Type of input (text/password/email/submit)
    element: 	 The element type (input/textarea/select)
    requireValidForm  Only available on submit types to control if they can be used if the form is in an invalid state {default=true}
    options:   Only available for element of type select
    placeholder: Placeholder text to display {default=""}
    value:       Value
    onClick:     Onclick javascript handler
    required:    Required to submit form HTML5 validation (required) (default="")
    validation 	JSON object with validation algorithm return true/false
    algorithm: email|range
    params: [range: min,max]
  State:
   @param  {bool}    showRequiredDiv       If the field is in focus and error
   @param  {bool}    isInFocus             If the field is in focus or not
   @param  {bool}    isDisabled            If the field is disabled or not
   @param  {string}  value                 The value in the field (user input)
 */
 export default class FormInput extends React.Component
 {
   _defaultSelectRow = <option key={'defaultRow'} value=''>{'Click to see options'}</option>;
   _startDate = new Date();

     // MARK: - Constructor
     constructor(props)
     {
         super(props);

         let value = props.value;
         if(props.data.type === 'checkbox')
         {
           value = props.data.checked;
         }
         else if(props.data.type === 'select' && props.data.multiSelectEnabled)
         {
           value = Array.isArray(props.data.value) ? props.data.value : [props.data.value];
         }

         this.state =
         {
             showRequiredDiv:   false,
             isInFocus:         false,
             isDisabled:        props.data.disabled,
             value:             value,
             isLoading:         false,
         };
     }

    // MARK: - Event handlers
    // Update parent
    onChange(evt)
    {
      var value = (this.props.data.type === 'file' ? evt.target.files[0] : (evt.target ? evt.target.value : evt));
      if(this.props.data.type === 'checkbox')
      {
        value = (evt.target.checked ? true : false);
      }
      if(this.props.data.type === 'select' && this.props.data.multiSelectEnabled)
      {
        value = [];
        for(let i = 0; i < evt.target.options.length; i++)
        {
          /* Go through options list and add selected to list
              but if the value in the event matches then we
              clicked a selected value and should unselect it   */
          if(evt.target.options[i].selected)
          {
            value.push(evt.target.options[i].value);
          }
        }
      }
      this.setState({ value: value });

      var change =
      {
        id:         this.props.data.id,
        value:      value,
        validation: this.props.data.validation,
        type:       this.props.data.type
      };

      // Notify form (parent)
      this.props.formInputOnChange(change);
    }

    /**
      *     Form will call this back after it validates itself.
      *     @param  {bool}  isValid     If the parent/form is valid or not
      */
    formStateChanged(isValid)
    {
      // If we are a submit button we will enable/disable ourselves
      if(this.props.data.type && (this.props.data.type === 'submit' || this.props.data.type === 'button'))
      {
        // If the button does not care about the form being valid we will tell it's valid
        // this way the user can use it (to cancel for example)
        if(this.props.data.requireValidForm === false)
        {
          this.setState({ isDisabled: false });
        }
        else
        {
          this.setState({ isDisabled: !isValid });
        }
      }
    }

    onFocus = () =>
    {
        this.setState({ isInFocus: true });
    }
    onFocusOut = () =>
    {
        this.setState({ showRequiredDiv: false, isInFocus: false });
    }

    // MARK: - Helpers
    async updateValue(value)
    {
      await this.setStateAsync({value: value });
      return true;
    }

    setStateAsync(state)
    {
      return new Promise((resolve) =>
      {
          this.setState(state, resolve)
      });
    }

    getQuestionsOnClick = async() => {
      this.setState({ isLoading: true });
      try
      {
        // Update model
        var response = await ApiRequest.sendRequest("post", {
          campaignID: this.props.campaignID
        }, "classy/questions", this.props.cookies.get('token'));
        if(response.data.error !== null)
        {
          this.setState({ isLoading: false });
          this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
          return;
        }
        console.log(response.data.results.questions.data);

        // Update models array
      
        this.setState({ questions: response.data.results.questions.data, isLoading: false });
      }
      catch(err)
      {
        this.setState({ isLoading: false });
        this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
      }
    }

    getAirtableSchemaOnClick = async() => {
      this.setState({ isLoading: true });
      try
      {
        // Update model
        var response = await ApiRequest.sendRequest("post", {
          baseId: this.props.baseId
        }, "airtable/schema", this.props.cookies.get('token'));
        if(response.data.error !== null)
        {
          this.setState({ isLoading: false });
          this.props.showAlert(true, 'Uh-oh', response.data.error, 'danger');
          return;
        }
        console.log(response.data.results.schema.tables);

        // Update models array
      
        this.setState({ airtableSchema: response.data.results.schema.tables, isLoading: false });
      }
      catch(err)
      {
        this.setState({ isLoading: false });
        this.props.showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
      }
    }


	   // MARK: - Render
    shouldComponentUpdate(nextProps, nextState)
    {
        // Only render if value changed, disabled state changed, or requirement div hidden/shown or focus changed or form is resetting
        return (this.state.value !== nextState.value ||
                this.state.isDisabled !== nextState.isDisabled ||
                this.props.errorMessage !== nextProps.errorMessage ||
                this.state.isInFocus !== nextState.isInFocus ||
                this.state.tooltipOpen !== nextState.tooltipOpen || 
                this.state.isLoading !== nextState.isLoading ||
                this.props.campaignID !== nextProps.campaignID ||
                this.state.questions !== nextState.questions);
    }

    truncate(input) {
      return input.length > 5 ? `${input.substring(0, 128)}...` : input;
    }

    render()
    {
      // Label and tooltip
      var label = '';
      if(this.props.data.label)
      {
        label = (<label>{this.props.data.label}</label>);
      }

      // Requirement
      var requirement = (this.props.data.validation && this.props.data.validation.requirement ? this.props.data.validation.requirement : '')
      var requirementDivIsHidden = true;

      // Class name
      var className   = (this.props.data.class ? this.props.data.class : '');
      if(this.props.errorMessage === '' || this.props.errorMessage === undefined)
      {
        try
        {
          // No error and value input so show valid
          if(this.state.value && this.state.value.length > 0) // Strings
          {
              className += 'valid';
          }
          else if(this.state.value && !isNaN(this.state.value)) // Numbers
          {
            className += 'valid';
          }
          else if(this.state.value === 0 && this.props.data.type === 'number')
          {
            className += 'valid';
          }
        }
        catch(err)
        {
          console.log(err);
          console.log(this.props.data.id);
        }
      }
      else
      {
        // Show requirement div  if error message
        if(this.props.errorMessage !== '-EV')
        {
          // If field not in focus show it as pink
          if(!this.state.isInFocus)
          {
            className += 'invalid';
          }
          // Otherwise show requirement text
          else
          {
            requirementDivIsHidden = false;
          }
        }
      }


      // Required
      //var required    = (this.props.data.required ? this.props.data.required : '');
      //console.log(this.props.data);
      var onClick = (e) =>
      {
        if(this.props.data.onClick)
        {
          this.props.data.onClick(e);
        }
      };

      // Button has different onclick
      if(this.props.data.type === 'button')
      {
        onClick = (e, params) =>
        {
          this.props.data.onClick(e, params);
        };
      }

      const ElementTag = `${this.props.data.element}`;

      const formInput = (
      (this.props.data.element === 'select' &&
      <div className="dropdown">
        <ElementTag
          type={this.props.data.type}
          id={this.props.data.id}
          value={this.state.value !== undefined ? this.state.value : ''}
          onChange={e => this.onChange(e)}
          onClick={e => onClick(e)}
          key={this.props.data.id}
          multiple={this.props.data.multiSelectEnabled}
          className={className}
          disabled={this.state.isDisabled}
          required={this.props.data.required}
          onFocus={this.onFocus}
          onBlur={this.onFocusOut}
          placeholder={'Click to select an option'}>
          {!this.props.data.multiSelectEnabled && this._defaultSelectRow}
          {this.props.data.options.map(entry =>
            <option key={entry.value} value={entry.value}>{entry.text}</option>
          )}
        </ElementTag>
      </div>)
      ||
      (this.props.data.element === 'time' &&
      <TimePicker
        showSecond={false}
        defaultValue={now}
        className=""
        onChange={e => this.onChange(e)}
        format={format}
        use12Hours
      />)
      ||
      (this.props.data.element === 'datetime' &&
      <DatePicker
        selected={(this.state.value ? new Date(this.state.value) : this._startDate)}
        onChange={e => this.onChange(e)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
        required={this.props.data.required}
      />)
      ||
      (this.props.data.element === 'date' &&
      <DatePicker
        selected={(this.state.value ? new Date(this.state.value) : this._startDate)}
        onChange={e => this.onChange(e)}
        dateFormat="MMMM d, yyyy"
        required={this.props.data.required}
      />)
      ||
      (this.props.data.type === 'checkbox' &&
      <ElementTag
        type={this.props.data.type}
        id={this.props.data.id}
        key={this.props.data.id}
        value={this.props.value}
        placeholder={this.props.data.placeholder}
        onClick={e => this.onChange(e)}
        className={className}
        required={this.props.data.required}
        disabled={this.state.isDisabled}
        onFocus={this.onFocus}
        onBlur={this.onFocusOut}
        defaultChecked={(typeof(this.props.data.value) === 'boolean' ? this.props.data.value : (this.props.data.value === 'true' ? true : false))}
    	/>)
      ||
      (this.props.data.type === 'button' &&
        <button
          id={this.props.data.id}
          key={this.props.data.id}
          type="submit"
          disabled={this.state.isDisabled}
          className="btn btn-block"
          onClick={(e, input) => onClick(e, this.props.data)}
          value={this.state.value}
        >{this.props.data.title}</button>
      )
      ||
      (this.props.data.type !== 'space' &&
      this.props.data.type !== 'object' &&
      <ElementTag
        type={this.props.data.type}
        id={this.props.data.id}
        key={this.props.data.id}
        value={this.props.data.type === 'file' ? '' : this.state.value}
        placeholder={this.props.data.placeholder}
        onChange={e => this.onChange(e)}
        onClick={e => onClick(e)}
        className={className}
        required={this.props.data.required}
        disabled={this.state.isDisabled}
        onFocus={this.onFocus}
        onBlur={this.onFocusOut}
        checked={this.props.data.type === 'checkbox' ? this.state.value : ''}
        rows="3"
    	/>)
      ||
      (this.props.data.type === 'object' &&
      <textarea
        type={this.props.data.type}
        id={this.props.data.id}
        key={this.props.data.id}
        value={JSON.stringify(this.state.value, undefined, 2)}
        placeholder={this.props.data.placeholder}
        onChange={e => this.onChange(e)}
        onClick={e => onClick(e)}
        className={className}
        required={this.props.data.required}
        disabled={this.state.isDisabled}
        onFocus={this.onFocus}
        onBlur={this.onFocusOut}
        checked={''}
        rows="3"
    	/>)
    );

    // Controls if this input is an entire row to itself or not
    return (
		<div className={this.props.rowClassName}>

      {this.props.isFirstSizeQuestion && this.props.campaignID && (
        <div className={'fetchButtons'}>
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={this.getQuestionsOnClick}
          >Get Questions From Classy</Button>
          {this.state.questions && (
            <Button
              className={'closeButton'}
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={() => this.setState({ questions: null })}
            >Hide</Button>
          )}
        </div>
      )}

      {this.props.isAirtableBaseQuestion && this.props.baseId && (
        <div className={'fetchButtons'}>
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={this.getAirtableSchemaOnClick}
          >Get Airtable Base Schema</Button>
          {this.state.airtableSchema && (
            <Button
              className={'closeButton'}
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={() => this.setState({ airtableSchema: null })}
            >Hide</Button>
         )}
        </div>
      )}

      {this.state.questions && (
        this.state.questions.map((question, index) => {
          return (
            <div className={'question-container'} key={`question-${index}`}>
              <p>Question<br/>{this.truncate(question.label)}</p>
              <p>ID<br/>{question.id}</p>
            </div>
          )
        })
      )}

      {this.state.airtableSchema && (
        this.state.airtableSchema.map((table, index) => {
          return (
            <div className={'question-container'} key={`table-${index}`}>
              <p>Table<br/>{table.name}</p>
              <p>ID<br/>{table.id}</p>
              <p>Fields</p>
              {table.fields.map((field, fieldIndex) => {
                return (
                  <p key={`field-${fieldIndex}`}>Field Name ({field.name}) ID {field.id}</p>
                )
              })}
            </div>
          )
        })
      )}

			{this.props.data.tooltip &&
        this.props.data.tooltipType === 'onlabel' &&
          <Tooltip
            title={`${label ? '' : this.props.data.tooltipQuestion + '\n'}${this.props.data.tooltip}`}
          >
            {label ? label : <p>{this.props.data.tooltipQuestion}</p>}
          </Tooltip>
      }

      {this.props.data.tooltip &&
        this.props.data.tooltipType === 'sidebutton' &&
          <div>
            {label}
            <p
              className='tooltip-question'
              onClick={ (e) => this.props.openTooltip({ message: this.props.data.tooltip, question: this.props.data.tooltipQuestion }) }
            >{this.props.data.tooltipQuestion}</p>
          </div>
      }

      {!this.props.data.tooltip && label}

			{formInput}
      <Requirement isHidden={requirementDivIsHidden}
          text={requirement}
      />
		</div>);
	}
}
